import styled from 'styled-components';

export const StyledMonitorVaultsSubTextOne = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.01px;
  line-height: 15px;
`;

export const StyledMonitorVaultsSubTextTwo = styled.div`
  display: flex;
  height: 15px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.01px;
  line-height: 15px;
`;

export const StyledMonitorVaultsCoinName = styled.div`
  width: 108px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02px;
  line-height: 19px;
`;

export const OutboundLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  flex: 0 0 15px;
  margin-left: 0px;
`;

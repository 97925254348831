import React from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  value: number | undefined | null;
  displayDecimals?: number;
  removeTrailingZeros?: boolean;
  defaultDisplay?: string;
  postfix?: string;
  prefix?: string;
  isLoading?: boolean;
  id?: string;
  abbreviate?: boolean;
};

// https://github.com/buildo/react-placeholder
// https://skeletonreact.com/
const FormatNumber: React.FC<Props> = (props) => {
  const [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() => {
    if (props.isLoading) {
      setShowLoader(true);
    }

    // Show loader a bits longer to avoid loading flash
    if (!props.isLoading && showLoader) {
      setShowLoader(false);
    }
  }, [props.isLoading, showLoader]);

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 });
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 });

  const prefix = props.prefix || '';
  const postfix = props.postfix || '';
  if (props.isLoading) {
    return (
      <div className="loadable-small">
        <animated.div style={fadeOutProps}>
          <div className="loader" />
        </animated.div>
      </div>
    );
  }

  let decimals = props.displayDecimals !== undefined ? props.displayDecimals : 2;
  let abbrLetter = '';
  let value = props.value;
  if (props.abbreviate && value) {
    if (value > 10**12) {
      value = value/(10**12);
      abbrLetter = ' T';
    }
    if (value > 10**9) {
      value = value/(10**9);
      abbrLetter = ' B';
    }
    if (value > 10**6) {
      value = value/(10**6);
      abbrLetter = ' M';
    }
    if (value > 10**3) {
      value = value/(10**3);
      abbrLetter = ' K';
    }
  }
  if (value && value > 100) {
    decimals = 1;
  }
  if (value && value > 1000) {
    decimals = 0;
  }

  if (value !== null) {
    let numValue = decimals > 0 ? value?.toFixed(decimals).replace(/\.?0+$/g, '') : value?.toFixed(decimals);
    return (
      <animated.div style={fadeInProps} id={props.id}>
        {prefix}
        {/* replace() removes trailing zeros */}
        <span className="cy-number-percent">{numValue}</span>
        {abbrLetter}
        {postfix}
      </animated.div>
    );
  }

  if (props.defaultDisplay) {
    return (
      <animated.div style={fadeInProps}>
        {prefix}
        {props.defaultDisplay}
        {postfix}
      </animated.div>
    );
  }
  return null;
};

export default FormatNumber;

import React from 'react';

type Props = {
  value: boolean;
};

const FormatBoolean: React.FC<Props> = (props) => {
  if (props.value) {
    return <div className="warning-text-color bold">Yes</div>;
  }

  return <div className="primary-color">No</div>;
};

export default FormatBoolean;

/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { StablePools } from 'common/models/monitorVaults';
import { StyledTableBodyTr, StyledTd, StyledTdItem } from 'common/styles/common.styles';
import React from 'react';
import FormatBoolean from '../FormatBoolean/FormatBoolean';
import FormatNumber from '../FormatPercent/FormatNumber';
import MonitorStablePoolsTablePositionDetails from './MonitorStablePoolsTablePositionDetails';
import { StyledMonitorVaultsCoinName, StyledMonitorVaultsSubTextOne } from './monitorStablePoolsTable.style';

type Props = {
  pool: StablePools;
};

const MonitorStablePoolsTablePosition: React.FC<Props> = (props) => {
  const [isPoolSelected, setIsPoolSelected] = React.useState(false);
  const toggleSelected = () => {
    setIsPoolSelected(!isPoolSelected);
  };

  return (
    <>
      <StyledTableBodyTr
        className={isPoolSelected ? 'no-border-bottom  cursor-pointer' : 'table-border-bottom  cursor-pointer'}
        key={props.pool.name}
        onClick={toggleSelected}
      >
        {/* ---------------------- */}
        {/* Name */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color">
            <StyledMonitorVaultsCoinName className="primary-text-color">
              <span>{props.pool.displayName}</span>
            </StyledMonitorVaultsCoinName>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Needs Rebalance */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatBoolean value={props.pool.needRebalance} />
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Alerts */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <ul>
              {props.pool.alerts?.map((item) => (
                <li key={item.name} className="pb-3">
                  <StyledMonitorVaultsSubTextOne key={item.name} className="primary-text-color">
                    {item.name}: {item.value}%
                  </StyledMonitorVaultsSubTextOne>
                </li>
              ))}
            </ul>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Warnings */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <ul>
              {props.pool.warnings?.map((item) => (
                <li key={item.name} className="pb-3">
                  <StyledMonitorVaultsSubTextOne key={item.name} className="primary-text-color">
                    {item.name}: {item.value}%
                  </StyledMonitorVaultsSubTextOne>
                </li>
              ))}
            </ul>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* oneTokenPriceFromVault */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatNumber value={props.pool.oneTokenPriceFromVault} displayDecimals={4} prefix="$" />
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* oneTokenRatio */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatNumber value={props.pool.oneTokenRatio} displayDecimals={1} postfix="%" />
          </StyledTdItem>
        </StyledTd>
      </StyledTableBodyTr>
      <MonitorStablePoolsTablePositionDetails
        isShown={isPoolSelected}
        pool={props.pool}
      />
    </>
  );
};

export default MonitorStablePoolsTablePosition;

import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { IchiJson } from 'common/models/ichiJson';
import { REFRESH_INTERVALS } from 'constants/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { listMonitorStablePoolsQuery } from 'graphql/queries/listMonitorStablePools.query';
import React from 'react';
import MonitorStablePoolsTable from 'common/components/MonitorStablePoolsTable/MonitorStablePoolsTable';
import { StablePools } from 'common/models/monitorVaults';
import { ContentTableWrapper } from './app.style';

type Props = {
  onlyNeedRebalance: boolean;
};

const MonitorStablePoolsContainer: React.FC<Props> = (props) => {
  const { loading, error, data } = useQuery<QueryItemsResponse<StablePools>>(listMonitorStablePoolsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listMonitorStablePools]
  });

  const StaticData: IchiJson = useStaticQuery(graphql`
    query {
      ichiJson {
        USDC_ONETOKEN_POOLS_TABLE {
          headers {
            name
            helpText
          }
        }
      }
    }
  `);

  const pools: StablePools[] = props.onlyNeedRebalance
    ? data?.listMonitorStablePools?.items.filter((v) => v.needRebalance) || []
    : data?.listMonitorStablePools?.items || [];

  const table = StaticData.ichiJson.USDC_ONETOKEN_POOLS_TABLE;

  if (error) {
    console.error(error);
  }

  return (
    <SpinnerContainer isLoading={loading}>
      <ContentTableWrapper>
        <MonitorStablePoolsTable table={table} pools={pools} />
      </ContentTableWrapper>
    </SpinnerContainer>
  );
};

export default MonitorStablePoolsContainer;

import React from 'react';

type Props = {
  value: number | undefined | null;
  displayDecimals?: number;
  defaultDisplay?: string;
  postfix?: string;
  prefix?: string;
  isLoading?: boolean;
  trunc?: boolean; // defaults to true
};

// https://github.com/buildo/react-placeholder
// https://skeletonreact.com/
const FormatFloat: React.FC<Props> = (props) => {
  const prefix = props.prefix || '';
  const postfix = props.postfix || '';
  const trunc = typeof props.trunc === 'undefined' ? true : props.trunc;
  if (props.value != null) {
    return (
      <span className="cy-number-float">
        {prefix}
        {Math.round(props.value).toLocaleString()}
        {postfix}
      </span>
    );
  // eslint-disable-next-line no-else-return
  } else if (props.defaultDisplay) {
    return (
      <span className="cy-number-float">
        {prefix}
        {props.defaultDisplay}
        {postfix}
      </span>
    );
  }
  return null;
};

export default FormatFloat;

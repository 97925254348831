import { gql } from '@apollo/client';

export const listMonitorStablePoolsQuery = gql`
  query ListMonitorStablePools {
    listMonitorStablePools {
      items {
        name
        needRebalance
        address
        displayName
        oneTokenRatio
        numAlerts
        numWarnings
        oneTokenValue
        usdcValue
        oneTokenPriceFromVault
        tvl
        warnings {
          name
          value
        }
        alerts {
          name
          value
        }
      }
    }
  }
`;

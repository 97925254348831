/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import Image from 'common/components/Image';
import { Table } from 'common/models/table';
import { StablePools } from 'common/models/monitorVaults';
import { StyledFlexAlignCenter, StyledTable, StyledTableHeadingTr, StyledTableTh } from 'common/styles/common.styles';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { TooltipWrapper } from '../Tooltip/tooltip.style';
import MonitorStablePoolsTablePosition from './MonitorStablePoolsTablePosition';

type Props = {
  table: Table;
  pools: StablePools[];
};

const MonitorStablePoolsTable: React.FC<Props> = (props) => {
  const pools = [...props.pools]?.sort((a, b) => +b.needRebalance - +a.needRebalance) ?? [];

  return (
    <>
      <StyledTable>
        <thead>
          <StyledTableHeadingTr className="table-border-bottom">
            {props.table?.headers?.map((header) => (
              <StyledTableTh key={header.name} className="sub-text-color">
                <StyledFlexAlignCenter>
                  <div className="mr-5">{header.name}</div>
                  {header.helpText && (
                    <TooltipWrapper>
                      <Tooltip message={header.helpText} position="top" bubbleSize={{ width: '200px' }}>
                        <Image className="icon-help" alt={header.helpText} />
                      </Tooltip>
                    </TooltipWrapper>
                  )}
                </StyledFlexAlignCenter>
              </StyledTableTh>
            ))}
          </StyledTableHeadingTr>
        </thead>
        <tbody>
          {pools?.map((pool) => (
            <MonitorStablePoolsTablePosition key={pool.name} pool={pool} />
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default MonitorStablePoolsTable;

import App from 'App';
import MonitorStablePoolsContainer from 'containers/MonitorStablePoolsContainer';
import React from 'react';

const Farm: React.FC<{}> = () => {
  return (
    <App>
      <MonitorStablePoolsContainer onlyNeedRebalance={false} />
    </App>
  );
};

export default Farm;
